/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Cell from "vux/src/components/cell/index.vue";
import Group from "vux/src/components/group/index.vue";
import Previewer from "vux/src/components/previewer/index.vue";
import Rater from "vux/src/components/rater/index.vue";
import TransferDom from "vux/src/directives/transfer-dom/index.js";
import ViewBox from "vux/src/components/view-box/index.vue";
import XButton from "vux/src/components/x-button/index.vue";
import XTextarea from "vux/src/components/x-textarea/index.vue";
export default {
  name: "RepairDetail",
  components: {
    Cell: Cell,
    Group: Group,
    XButton: XButton,
    ViewBox: ViewBox,
    XTextarea: XTextarea,
    Previewer: Previewer,
    Rater: Rater
  },
  data: function data() {
    return {
      imgList: [],
      //图片列表数组
      showImgList: [],
      repairInfo: {
        dicFmtMap: {
          repairTypeCn: ''
        }
      },
      repairResult: 0,
      resultRemark: '',
      options: {
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var thumbnail = document.querySelectorAll('.previewer-demo-img')[index];
          var pageYScroll = window.scrollY || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      },
      optionsSecond: {
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var thumbnail = document.querySelectorAll('.previewer-demo-img-second')[index];
          var pageYScroll = window.scrollY || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      }
    };
  },
  directives: {
    TransferDom: TransferDom
  },
  computed: {
    bodyBottom: function bodyBottom() {
      return this.repairInfo.repairState === '1' && this.repairInfo.readFlag !== '2' ? "0.64rem" : "0";
    }
  },
  methods: {
    showSecond: function showSecond(index) {
      this.$refs.previewerSecond.show(index);
    },
    show: function show(index) {
      this.$refs.previewer.show(index);
    },
    submitData: function submitData() {
      var _this = this;

      this.$utils.Tools.loadShow();
      var params = {
        id: this.$route.params.state,
        repairResult: this.repairResult,
        resultRemark: this.resultRemark,
        readFlag: '2'
      };
      this.$api.Repairs.updateByPrimaryKey(params).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.$utils.Tools.toastShow(msg);

          if (_this.$route.params.notification) {
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          } else {
            _this.$router.replace("/RepairList");
          }
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getRepairInfo: function getRepairInfo() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Repairs.selectByPrimaryKey(this.$route.params.state).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this2.$utils.Tools.loadHide();

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.repairInfo = data;

          if (data.photoList != null) {
            for (var i = 0; i < data.photoList.length; i++) {
              _this2.imgList.push({
                src: data.photoList[i],
                msrc: data.photoList[i]
              });
            }
          }

          if (data.resultPhotoList != null) {
            for (var _i = 0; _i < data.resultPhotoList.length; _i++) {
              _this2.showImgList.push({
                src: data.resultPhotoList[_i],
                msrc: data.resultPhotoList[_i]
              });
            }
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.getRepairInfo();
  },
  mounted: function mounted() {
    this.$utils.Store.setItem("repair", this.$route.params.index);

    if (this.$route.params.notification) {
      this.$utils.Tools.pushHistory();
    }
  },
  destroyed: function destroyed() {}
};